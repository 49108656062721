import React from 'react'
import DeviceCreateForm from '../../../components/forms/DeviceCreateForm'
import { Flex } from '@chakra-ui/react'

const CreateDevice = () => {
  return (
    <Flex marginTop={10} flexDir="column">

<DeviceCreateForm/>


</Flex>
  )
}

export default CreateDevice